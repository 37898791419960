import React, { createContext, useState } from "react";

export const DrumConfigurationContext = createContext();

export const DrumConfigurationProvider = ({ children }) => {
  const [drumConfigurations, setDrumConfigurations] = useState({});

  const setDrumConfiguration = (machineIndex, newConfig) => {
    setDrumConfigurations((prevConfigs) => {
      const updatedConfigs = { ...prevConfigs, [machineIndex]: newConfig };
      return updatedConfigs;
    });
  };

  const getDrumConfiguration = (machineIndex) => {
    return (
      drumConfigurations[machineIndex] || {
        columnsPerRow: [],
        mergedRows: [],
        mergedRowsLeft: [],
        mergedRowsRight: [],
        options: [],
        rows: 0,
        columns: 0,
      }
    );
  };

  const countGroups = (mergedRowsSide) => {
    let groups = 0;
    const numRows = mergedRowsSide.length;

    for (let i = 0; i < numRows; i++) {
      if (i === 0 || !mergedRowsSide[i - 1]) {
        groups++;
      }
    }
    return groups;
  };

  const calculateTotalCells = (data) => {
    const {
      columnsPerRow,
      mergedRows,
      mergedRowsLeft,
      mergedRowsRight,
      columns,
    } = data;

    if (columns === 2 && mergedRowsLeft && mergedRowsRight) {
      // Logic for columns === 2 (d810, d1080)
      const leftGroups = countGroups(mergedRowsLeft);
      const rightGroups = countGroups(mergedRowsRight);

      const totalCells = leftGroups + rightGroups;
      return totalCells;
    } else {
      // Logic for columns !== 2
      let totalCells = 0;
      const numRows = columnsPerRow.length;
      let rowIndex = 0;

      while (rowIndex < numRows) {
        // If the current row is not merged with the previous one
        if (rowIndex === 0 || !mergedRows[rowIndex - 1]) {
          const columnsInGroup = columnsPerRow[rowIndex];

          // Move the pointer to the end of the merged group
          rowIndex++;
          while (rowIndex < numRows && mergedRows[rowIndex - 1]) {
            rowIndex++;
          }

          // The number of cells in the group is the number of columns in one row
          totalCells += columnsInGroup;
        } else {
          // This row is merged with the previous one, skip it
          rowIndex++;
        }
      }
      return totalCells;
    }
  };

  const getNumberOfCells = (machineIndex) => {
    const config = getDrumConfiguration(machineIndex);
    return calculateTotalCells(config);
  };

  return (
    <DrumConfigurationContext.Provider
      value={{
        drumConfigurations,
        setDrumConfiguration,
        getDrumConfiguration,
        getNumberOfCells,
      }}
    >
      {children}
    </DrumConfigurationContext.Provider>
  );
};
