import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import ContactPage from "./pages/ContactPage/ContactPage";
import InstallationPage from "./pages/InstallationPage/InstallationPage";
import MachinesPage from "./pages/MachinesPage/MachinesPage";
import SummaryPage from "./pages/SummaryPage/SummaryPage";
import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import { AppContext } from "./context/AppContext";
import { FormProvider } from "./context/FormContext";
import { DrumConfigurationProvider } from "./context/DrumConfigurationContext";

const App = () => {
	const { currentPage } = useContext(AppContext);
	const [loggedIn, setLoggedIn] = useState(false);

	useEffect(() => {
		const handleLoginSubmit = () => {
			let password = prompt("Please enter your password", "");

			if (password === "asd-admin") {
				setLoggedIn(true);
			} else {
				handleLoginSubmit();
			}
		};

		if (process.env.NODE_ENV === "production") {
			handleLoginSubmit();
		} else {
			setLoggedIn(true);
		}
	}, []);

	const renderSubpage = () => {
		switch (currentPage) {
			case 0:
				return <ContactPage />;
			case 1:
				return <InstallationPage />;
			case 2:
				return <MachinesPage />;
			case 3:
				return <SummaryPage />;
			default:
				return <ContactPage />;
		}
	};

	return (
		<div className="App">
			{loggedIn ? (
				<div>
					<Header />
					<div className="main-card">
						<Navbar />
						<DrumConfigurationProvider>
							<FormProvider>
								<main>{renderSubpage()}</main>
							</FormProvider>
						</DrumConfigurationProvider>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default App;
