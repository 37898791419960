const countryCodes = [
    { code: "AL", phoneCode: "+355" }, // Albania
    { code: "AD", phoneCode: "+376" }, // Andorra
    { code: "AT", phoneCode: "+43" },  // Austria
    { code: "BY", phoneCode: "+375" }, // Belarus
    { code: "BE", phoneCode: "+32" },  // Belgium
    { code: "BA", phoneCode: "+387" }, // Bosnia and Herzegovina
    { code: "BG", phoneCode: "+359" }, // Bulgaria
    { code: "HR", phoneCode: "+385" }, // Croatia
    { code: "CY", phoneCode: "+357" }, // Cyprus
    { code: "CZ", phoneCode: "+420" }, // Czech Republic
    { code: "DK", phoneCode: "+45" },  // Denmark
    { code: "EE", phoneCode: "+372" }, // Estonia
    { code: "FO", phoneCode: "+298" }, // Faroe Islands
    { code: "FI", phoneCode: "+358" }, // Finland
    { code: "FR", phoneCode: "+33" },  // France
    { code: "DE", phoneCode: "+49" },  // Germany
    { code: "GI", phoneCode: "+350" }, // Gibraltar
    { code: "GR", phoneCode: "+30" },  // Greece
    { code: "HU", phoneCode: "+36" },  // Hungary
    { code: "IS", phoneCode: "+354" }, // Iceland
    { code: "IE", phoneCode: "+353" }, // Ireland
    { code: "IT", phoneCode: "+39" },  // Italy
    { code: "LV", phoneCode: "+371" }, // Latvia
    { code: "LI", phoneCode: "+423" }, // Liechtenstein
    { code: "LT", phoneCode: "+370" }, // Lithuania
    { code: "LU", phoneCode: "+352" }, // Luxembourg
    { code: "MK", phoneCode: "+389" }, // North Macedonia
    { code: "MT", phoneCode: "+356" }, // Malta
    { code: "MD", phoneCode: "+373" }, // Moldova
    { code: "MC", phoneCode: "+377" }, // Monaco
    { code: "ME", phoneCode: "+382" }, // Montenegro
    { code: "NL", phoneCode: "+31" },  // Netherlands
    { code: "NO", phoneCode: "+47" },  // Norway
    { code: "PL", phoneCode: "+48" },  // Poland
    { code: "PT", phoneCode: "+351" }, // Portugal
    { code: "RO", phoneCode: "+40" },  // Romania
    { code: "RU", phoneCode: "+7" },   // Russia
    { code: "SM", phoneCode: "+378" }, // San Marino
    { code: "RS", phoneCode: "+381" }, // Serbia
    { code: "SK", phoneCode: "+421" }, // Slovakia
    { code: "SI", phoneCode: "+386" }, // Slovenia
    { code: "ES", phoneCode: "+34" },  // Spain
    { code: "SE", phoneCode: "+46" },  // Sweden
    { code: "CH", phoneCode: "+41" },  // Switzerland
    { code: "UA", phoneCode: "+380" }, // Ukraine
    { code: "GB", phoneCode: "+44" },  // United Kingdom
    { code: "VA", phoneCode: "+379" }, // Vatican City
];

export default countryCodes;
