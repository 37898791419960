// SummaryPage.js

import React, { useContext, useState, useEffect } from "react";
import { FormContext } from "../../context/FormContext";
import Buttons from "../../components/Buttons/Buttons";
import "./SummaryPage.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import { Typography } from "@mui/material";

const SummaryPage = () => {
  const [formState, setFormState] = useState({
    comment: "",
    rulesAccepted: "",
  });

  const { t } = useTranslation();
  const { formData, updateFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});

  const isFieldValid = (fieldName) => {
    return !errors[fieldName];
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    setFormState({
      ...formState,
      [name]: newValue,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = ["rulesAccepted"];

    requiredFields.forEach((field) => {
      const fieldValue = formState[field];
      if (fieldValue === null || fieldValue === undefined || fieldValue === "") {
        newErrors[field] = true;
      } else {
        newErrors[field] = false;
      }
    });

    setErrors(newErrors);
    for (const key in newErrors) {
      if (newErrors[key]) {
        return false;
      }
    }

    return true;
  };

  const switchInputError = (fieldName, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const newValue = !formState.rulesAccepted;
    switchInputError("rulesAccepted", !newValue);
    setFormState({
      ...formState,
      rulesAccepted: newValue,
    });
  };

  const handleButtonClick = () => {
    const summaryForm = {
      ...formState,
    };
    updateFormData({ summaryForm });
  };

  useEffect(() => {
    if (formData && formData.summaryForm) {
      const summaryForm = formData.summaryForm;
      setFormState({
        comment: summaryForm.comment || "",
        rulesAccepted: summaryForm.rulesAccepted || "",
      });
    }
  }, [formData]);

  return (
    <div className="summary-page">
      <h2>{t("page.summary.header.summary")}</h2>

      {/* Contact Form Summary */}
      <div className="section">
        <h3>{t("page.summary.header.contact")}</h3>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.name")}</span>
          <span className="summary-value">{formData.contactForm?.name}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.company")}</span>
          <span className="summary-value">{formData.contactForm?.company}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.companyAddress")}
          </span>
          <span className="summary-value">{formData.contactForm?.address}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.nip")}</span>
          <span className="summary-value">{formData.contactForm?.nip}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.phone")}</span>
          <span className="summary-value">{formData.contactForm?.phone}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.email")}</span>
          <span className="summary-value">{formData.contactForm?.email}</span>
        </div>
      </div>

      {/* Billing Form Summary */}
      <div className="section">
        <h3>{t("page.summary.header.billing")}</h3>
        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.billingCompany")}
          </span>
          <span className="summary-value">{formData.billingForm?.billingCompany}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.billingAddress")}
          </span>
          <span className="summary-value">{formData.billingForm?.billingAddress}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.billingNip")}</span>
          <span className="summary-value">{formData.billingForm?.billingNip}</span>
        </div>
      </div>

      {/* Installation Form Summary */}
      <div className="section">
        <h3>{t("page.summary.header.installation")}</h3>
        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.installationCountry")}
          </span>
          <span className="summary-value">
            {formData.installationForm?.country
              ? t(`countries.${formData.installationForm?.country}`)
              : ""}
          </span>
        </div>
        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.installationLocation")}
          </span>
          <span className="summary-value">{formData.installationForm?.address}</span>
        </div>

        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.customerCompany")}
          </span>
          <span className="summary-value">{formData.installationForm?.company}</span>
        </div>
      </div>

      {/* Machines Details Summary */}
      <div className="section">
        <h3>{t("page.summary.header.machine")}</h3>
        {formData.machinesForms &&
          formData.machinesForms.map((machine, index) => (
            <div key={index} className="machine-summary">
              <h4>{`${index + 1}`}</h4>
              <div className="summary-row">
                <span className="summary-label">
                  {t("page.summary.label.machineModel")}
                </span>
                <span className="summary-value font-bold-700">
                  {machine.machineType} {machine.version}
                </span>
              </div>
              <div className="summary-row">
                <span className="summary-label">
                  {t("page.machines.label.barcodeReader")}:
                </span>
                <span className="summary-value">
                  {machine.barcodeReader === "standard"
                    ? t("page.machines.radio.standardReader")
                    : t("page.machines.radio.customReader")}
                </span>
              </div>
              {machine.drumScanner && (
                <div className="summary-row">
                  <span className="summary-label">
                    {t("page.machines.label.drumScanner")}
                  </span>
                  <span className="summary-value">{machine.drumScanner}</span>
                </div>
              )}
              <div className="summary-row">
                <span className="summary-label">
                  {t("page.summary.label.machineColor")}
                </span>
                <span className="summary-value">
                  {machine.color === "standardcolor"
                    ? t("page.machines.radio.standardColor")
                    : t("page.machines.radio.nonstandardColor")}
                </span>
              </div>
            </div>
          ))}
      </div>

      <div className="input-box additional-comment">
        <label htmlFor="comment">{t("page.summary.label.comment")}</label>
        <textarea
          name="comment"
          value={formState.comment}
          onChange={handleInputChange}
        />
      </div>

      <section className="rules">
        <a
          href="https://asdsystems.eu/content/oferta"
          className="contract-link"
          target="_blank"
          rel="noreferrer"
        >
          <span>{t("global.btn.contract")}</span>
          <LinkIcon />
        </a>
        <div className="rules-box">
          <input
            type="checkbox"
            name="rulesAccepted"
            value={formState.rulesAccepted}
            checked={formState.rulesAccepted}
            onChange={handleCheckboxChange}
          />
          <label
            name="rulesAccepted"
            className="contractSubmit"
            onClick={handleCheckboxChange}
          >
            {t("page.summary.label.contractSubmit")}
          </label>
        </div>
        {!isFieldValid("rulesAccepted") && (
          <Typography mt={2} className="error-message">
            {t("page.summary.validation.global.required")}
          </Typography>
        )}
      </section>

      {/* Generate PDF Button */}
      <Buttons validateForm={validateForm} handleButtonClick={handleButtonClick} />
    </div>
  );
};

export default SummaryPage;
