import React, { useContext } from "react";
import DrumConfiguratorPage from "../../DrumConfiguratorPage/DrumConfiguratorPage";
import { DrumConfigurationContext } from "../../../context/DrumConfigurationContext";
import { useTranslation } from "react-i18next";

const DrumConfigurationModal = ({
  isPopupOpen,
  machineType,
  fullConfiguration,
  handleClosePopup,
  machineIndex,
}) => {
  const { t } = useTranslation();
  const { getNumberOfCells } = useContext(DrumConfigurationContext);

  const numberOfCells = getNumberOfCells(machineIndex);

  if (!isPopupOpen) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          borderBottom: "1px solid #D9D9D9",
          width: "80%",
          padding: "10px 100px",
          backgroundColor: "#F7F8F9",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          margin: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h3>{t("modals.configurator.title")}</h3>
      </div>
      <div
        style={{
          backgroundColor: "white",
          padding: "30px 40px 40px 160px",
          position: "relative",
          width: "80%",
          height: "80%",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          overflow: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            paddingRight: "80px",
            justifyContent: "space-between",
            marginBottom: "26px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "33%",
            }}
          >
            <img
              style={{ marginRight: "10px", height: "85px" }}
              src={process.env.PUBLIC_URL + "/" + machineType + ".png"}
              alt="machine"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "middle",
              }}
            >
              <p style={{ color: "#999999", margin: "0" }}>
                {t("modals.configurator.model")}
              </p>
              <h3 style={{ margin: "0" }}>{machineType}</h3>
            </div>
          </div>
          <div
            style={{
              width: "33%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "#999999",
                textAlign: "center",
                margin: "10px",
              }}
            >
              {t("modals.configurator.cellAmount")}: <b>{numberOfCells}</b>
            </p>
            <button
              onClick={fullConfiguration}
              className="btn-back"
              style={{ padding: "3px", fontSize: "12px" }}
            >
              {t("modals.configurator.fullConfiguration")}
            </button>
          </div>
          <div
            style={{
              width: "33%",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <button
              onClick={handleClosePopup}
              className="btn-next"
              style={{ width: "160px", marginLeft: "30px" }}
            >
              {t("global.btn.save")}
            </button>
          </div>
        </div>
        <DrumConfiguratorPage machineIndex={machineIndex} />
      </div>
    </div>
  );
};

export default DrumConfigurationModal;
